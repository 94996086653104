import React, {useEffect} from 'react';
import axios from "axios";
import {Apis} from "../../../util/Apis";
import FormGroup from "../../../components/FormGroup";
import {useForm} from "react-hook-form";
import {Input} from "@wargostec/react-components";

const InvoiceGenerateAll = ({setAction, setTitle}) => {

  const {register, handleSubmit, watch, reset, control} = useForm();

  const create = async (params) => {
    const input = params.date.split('-');
    params.year = input[0];
    params.month = input[1];

    return await axios.post(Apis.BILLING + '/invoices/batch', params).catch((err) => {
    });
  }

  useEffect(() => {
    setTitle("Generacion masiva");
  }, []);

  useEffect(() => {
    setAction(() => handleSubmit(create))
  }, [])

  return (
    <>
      <React.Fragment>
        <FormGroup>
          <label>Fecha</label>
          <Input type="month" {...register("date")} />
        </FormGroup>
      </React.Fragment>
    </>
  );
};

export default InvoiceGenerateAll;
