import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useNavigate, useSearchParams} from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import {Controller, useForm} from "react-hook-form";
import {Button, FlexContainer, Table} from "@wargostec/react-components";
import PageContainerFull from "../../components/PageContainerFull";
import PageSection from "../../components/PageSection";
import PageTitle from "../../components/Layaout/PageTitle";
import {Col, Row} from "react-bootstrap";
import SelectSalas from "../../Selects/SelectSalas";
import {GrClearOption} from "react-icons/gr";
import {ContainerLoading} from "../SalasList";
import {Loading} from "../../components/forms/Loading";
import NoDataTable from "../../components/NoDataTable";
import CardOverview, {CardOverviewContainer} from "../../components/CardOverview";
import {IoEyeSharp} from "react-icons/io5";
import {FaRegListAlt} from "react-icons/fa";
import {BsDeviceSsd} from "react-icons/bs";
import {Apis} from "../../util/Apis";

const DevicesGroupedList = () => {

  const [searchParams, setSearchParams] = useSearchParams();
  const salaIdParam = searchParams.get('salaId') || '';
  const [params, setParams] = useState({salaId: salaIdParam});
  const {data, loading, error, fetchData} = useFetch(Apis.TGM + '/pangolin/grouped', [], params);
  const {control} = useForm({defaultValues: {salaId: salaIdParam}});
  const navigate = useNavigate();

  const getData = (params) => {
    setParams(params)
    setSearchParams(params)
  }

  const clear = () => {
    getData({})
  }

  useEffect(() => {
    if (params) {
      fetchData()
    }
  }, [params])

  useEffect(() => {
    fetchData()
  }, [])

  function goEditMachine(salaId) {
    navigate(`/devices?salaId=${salaId}`);
  }

  const actionValue = useCallback(props => <>{
    <div>
      <Button size={'xs'} variant={'light'} onClick={() => goEditMachine(props.row.original.salaId)} icon={<IoEyeSharp />}/>
    </div>
  }</>, []);

  const columns = useMemo(
    () => [
      {
        header: 'SalaID',
        accessorKey: 'salaId',
        meta: {
          headerClassName: 'text-left',
          columnClassName: 'text-left',
        }
      },
      {
        header: 'Sala',
        accessorKey: 'name',
        meta: {
          headerClassName: 'text-left',
          columnClassName: 'text-left',
        }
      },
      {
        header: 'Pangolin',
        accessorKey: 'quantity',
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        }
      },
      {
        header: 'Asignados',
        accessorKey: 'assigned',
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        }
      },
      {
        header: 'Sin asignar',
        accessorKey: 'unassigned',
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        }
      },
      {
        header: 'Acciones',
        cell: actionValue
      },
    ]
    , []
  )

  return (
    <PageContainerFull>
      <PageSection>
        <PageTitle title={'Dispositivos por sala'}/>
        <FlexContainer column gap='20px'>
          <Row>
            <Col>
              <div style={{width: '300px'}}>
                <Controller
                  name="salaId"
                  control={control}
                  render={({field}) => (
                    <SelectSalas
                      {...field}
                      onChange={(value) => {
                        field.onChange(value);
                        getData({salaId: value});
                      }}
                    />
                  )}
                />
              </div>
            </Col>
            <Col>
              <Button variant={'primary'} onClick={clear} icon={<GrClearOption/>}/>
            </Col>
          </Row>

        </FlexContainer>
      </PageSection>

      <PageSection>
        <CardOverviewContainer gridColumns='repeat(2, 1fr)'>
          <CardOverview
            title={data && data.salas}
            subtitle='Salas'
            icon={<FaRegListAlt size={20}/>}
          />
          <CardOverview
            title={data && data.total}
            subtitle='Dispositivos'
            icon={<BsDeviceSsd size={20}/>}
            bgcolor={'#FFC107'}
          />
        </CardOverviewContainer>

      </PageSection>

      <PageSection>
        {
          loading ?
            <ContainerLoading>
              <Loading variant='secondary'/>
            </ContainerLoading>
            :
            <div>
              {
                data.data && data.data.length > 0 ?
                  <Table columns={columns} data={data.data && data.data} pageSize={100}/>
                  :
                  <NoDataTable text={"No existen dispositivos aun"}/>
              }
            </div>
        }
      </PageSection>
    </PageContainerFull>
  );
};

export default DevicesGroupedList;
