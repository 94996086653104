import React from 'react'
import styled from 'styled-components'
import {BsBookmarkStarFill} from 'react-icons/bs'
import {Card} from "@wargostec/react-components";

export function getContrastColor(hexColor) {
  const r = parseInt(hexColor.slice(1, 3), 16);
  const g = parseInt(hexColor.slice(3, 5), 16);
  const b = parseInt(hexColor.slice(5, 7), 16);
  const luminance = 0.299 * r + 0.587 * g + 0.114 * b;
  return luminance > 128 ? '#000000' : '#ffffff';
}

export const FlexLayout = styled.div`
  display: flex;
  flex-direction: ${props => props.column ? 'column' : 'row'};
  justify-content: ${props => props.justifyContent || 'flex-start'};
  align-items: ${props => props.alignItems || 'flex-start'};
  padding: ${props => props.padding || '0px'};
  margin: ${props => props.margin || '0px'};
  gap: ${props => props.gap || '8px'};
`

export const CardOverviewContainer = styled.div`
    display: grid;
    grid-template-columns:  ${props => props.gridColumns ? props.gridColumns : 'repeat(5, 1fr)'};
    grid-gap: 1rem;
    grid-auto-flow: row dense;
    transition: 0.5s all ease-in-out;
    margin-bottom: 16px;
    @media screen and (max-width: 1024px) {
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 0.5rem;
    }
    @media screen and (max-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 0.5rem;
    }
    @media screen and (max-width: 468px) {
        grid-template-columns: 1fr;
        grid-gap: 0.5rem;
    }
`

export const IconContainer = styled(FlexLayout)`
    background: ${props => props.bgColor || '#e5e5e5'};
    color: ${props => (props.bgColor ? getContrastColor(props.bgColor) : '#0A0A0A')};
    height: 36px;
    width: 36px;
    border-radius: 6px;
    align-items: center;
    justify-content: center;
`

const CardOverview = ({title, subtitle, onClick, icon, bgColor, menu}) => {
  return (
    <Card onClick={onClick} style={{cursor: onClick ? 'pointer' : 'auto', marginBottom: '0px', padding: '12px'}}>
      <FlexLayout alignItems='flex-start'>
        <FlexLayout alignItems='center' style={{flex: 1}}>
          <IconContainer bgcolor={bgColor}>
            {icon ? icon : <BsBookmarkStarFill size={16} />}
          </IconContainer>
          <FlexLayout column gap='0' style={{flex: 1}}>
            <strong style={{fontSize: '18px', lineHeight: '20px'}}>{title}</strong>
            <span style={{
              color: '#212529',
              fontWeight: '400',
              fontSize: '14px',
            }}>{subtitle}</span>
          </FlexLayout>
        </FlexLayout>
        {menu && 
          <div>
            {menu}
          </div>
        }
      </FlexLayout>
    </Card>
  )
}

export default CardOverview
