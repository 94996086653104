import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {CreateStyles} from './styles/global';
import {ToastContainer} from "react-toastify";
import WauthProvider from "./auth/WauthProvider";
import CallbackPage from "./login/CallbackPage";
import LogoutPage from "./login/LogoutPage";
import UserList from "./pages/UserList";
import Layout from "./components/Layaout/Layout";
import SalasList from "./pages/SalasList";
import React from "react";
import NewSalas from "./pages/salas/NewSalas";
import EditSalas from "./pages/salas/EditSalas";
import VersionList from "./pages/Versiones/VersionList";
import BannerList from "./pages/BannerList";
import Midas from "./pages/Midas";
import MakerList from "./pages/MakerList";
import TenantList from "./pages/tenants/TenantList";
import TenantEdit from "./pages/tenants/TenantEdit";
import TenantNew from "./pages/tenants/TenantNew";
import OnlineSystem from "./pages/OnlineSystem";
import MakerEdit from "./pages/MakerEdit";
import MakerNew from "./pages/MakerNew";
import ModelList from "./pages/ModelList";
import ModelNew from "./pages/ModelNew";
import ModelEdit from "./pages/ModelEdit";
import Permissions from "./pages/permissions/Permissions";
import ClientsList from "./pages/clients/ClientsList";
import Ludopatas from "./pages/Ludopatas/Ludopatas";
import ActionsEndpointsList from "./pages/ActionsEndpointsList";
import ChannelList from "./pages/Server/ChannelList";
import ChannelEdit from "./pages/Server/ChannelEdit";
import DevicesList from "./pages/Server/DevicesList";
import ServerList from "./pages/Server/servers/ServerList";
import DevicesEdit from "./pages/Server/DevicesEdit";
import FirmwareList from "./pages/Server/FirmwareList";
import FirmwareEdit from "./pages/Server/FirmwareEdit";
import FirmwareNew from "./pages/Server/FirmwareNew";
import ProgrammingList from "./pages/Server/ProgrammingList";
import ProgrammingEdit from "./pages/Server/ProgrammingEdit";
import BillConfig from "./pages/billing/BillConfig";
import InvoiceList from "./pages/billing/InvoiceList";
import ModelBySala from "./pages/ModelBySala";
import PangolinLoteList from "./pages/Server/pangolin-lote/PangolinLoteList";
import PangolinLoteNew from "./pages/Server/pangolin-lote/PangolinLoteNew";
import PangolinLoteEdit from "./pages/Server/pangolin-lote/PangolinLoteEdit";
import CreateTicket from "./pages/CreateTicket";
import Tramas from "./pages/Server/Tramas";
import DevicesGroupedList from "./pages/Server/DevicesGroupedList";

function App() {
  return (
    <BrowserRouter basename={'/'}>
      <WauthProvider
        domain={'https://accounts.wargos.cloud'}
        clientId={'DBqTwXTGkuoVzv1YojUym0Z7bQseGx2S'}
        redirectUri={window.location.origin + "/callback"}
      >
        <CreateStyles/>
        <ToastContainer/>
          <Routes>
            <Route path='/' element={<Layout />}>
              <Route path='users' element={<UserList />} />
              <Route path="salas-list" element={<SalasList />} />
              <Route path="salas-list" element={<SalasList/>}/>
              <Route path="salas-new" element={<NewSalas/>}/>
              <Route path="salas-edit/:id" element={<EditSalas/>}/>
              <Route path="detail-version/:id" element={<VersionList/>}/>
              <Route path="banner-list" element={<BannerList/>}/>
              <Route path="midas" element={<Midas/>}/>
              <Route path="fabricante" element={<MakerList/>}/>
              <Route path="tenants-list" element={<TenantList/>}/>
              <Route path="tenants-edit/:id" element={<TenantEdit/>}/>
              <Route path="tenants-new" element={<TenantNew/>}/>
              <Route path="sistema-online" element={<OnlineSystem/>}/>
              <Route path="edit-salas/:id" element={<EditSalas/>}/>
              <Route path="new-salas" element={<NewSalas/>}/>
              <Route path="fabricante-edit/:id" element={<MakerEdit/>}/>
              <Route path="fabricante-new" element={<MakerNew/>}/>
              <Route path="modelo" element={<ModelList/>}/>
              <Route path="modelo-new" element={<ModelNew/>}/>
              <Route path="model-edit/:id/:modelId" element={<ModelEdit/>}/>
              <Route path="permissions" element={<Permissions/>}/>
              <Route path="clients" element={<ClientsList/>}/>
              <Route path="ludopatas" element={<Ludopatas/>}/>
              <Route path="user" element={<UserList/>}/>
              <Route path="actions-endpoints/:actionId" element={<ActionsEndpointsList/>}/>
              <Route path="channel-list" element={<ChannelList/>}/>
              <Route path="channel-edit/:id" element={<ChannelEdit/>}/>
              <Route path="devices" element={<DevicesList/>}/>
              <Route path="server-list" element={<ServerList/>}/>
              <Route path="devices/:id" element={<DevicesEdit/>}/>
              <Route path="firmware" element={<FirmwareList/>}/>
              <Route path="firmware/:id" element={<FirmwareEdit/>}/>
              <Route path="firmware-new" element={<FirmwareNew/>}/>
              <Route path="tramas" element={<Tramas/>}/>
              <Route path="programming" element={<ProgrammingList/>}/>
              <Route path="devices-grouped" element={<DevicesGroupedList/>}/>
              <Route path="programming/:id" element={<ProgrammingEdit/>}/>
              <Route path="billing" element={<BillConfig/>}/>
              <Route path="invoice-list" element={<InvoiceList/>}/>
              <Route path="model-by-sala" element={<ModelBySala/>}/>
              <Route path="create-ticket" element={<CreateTicket/>}/>
              <Route path="pangolin-lote-list" element={<PangolinLoteList/>}/>
              <Route path="pangolin-lote-new" element={<PangolinLoteNew/>}/>
              <Route path="pangolin-lote-edit/:id" element={<PangolinLoteEdit/>}/>
              <Route path="/" element={<UserList />} />
            </Route>
            <Route path='callback' element={<CallbackPage/>}/>
            <Route path='logout' element={<LogoutPage/>}/>
          </Routes>
      </WauthProvider>
    </BrowserRouter>
  );
}

export default App;
