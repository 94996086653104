import React, {useEffect} from "react";
import axios from "axios"
import {Apis} from "../../util/Apis";
import PermissionsFormAction from "./PermissionsFormAction";

const ActionNew = ({product, setAction, setTitle}) => {

  const save = (params) => {
    params.hash = Math.random().toString(36).slice(2, 8);
    params.level = "Admin";
    params.product = product;
    return axios.post(Apis.USER + '/actions', params);
  }

  useEffect(() => {
    setTitle("Nueva Acción")
  }, [])

  return (
    <>
      <PermissionsFormAction value={{}} onSubmit={save} setAction={setAction}/>
    </>
  )
}

export default ActionNew
